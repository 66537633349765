import { Injectable } from '@angular/core';
import { IComboSelectionChangingEventArgs, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';


@Injectable({
  providedIn: 'root'
})
export class ComboSelectFunctionsService {

  constructor() { }


  comboOpening(label: HTMLLabelElement) {
    label.classList.add("simple-select-label--active")
  }

  comboClosing(label: HTMLLabelElement, selectedvalue: any) {

    if (selectedvalue) {
      label.classList.add("simple-select-label--selected")
      label.classList.remove("simple-select-label--active")
      if (selectedvalue.length === 0) {
        label.classList.remove("simple-select-label--active", "simple-select-label--selected")
      }
    } else {
      label.classList.remove("simple-select-label--active", "simple-select-label--selected")
    }
  }


  comboChanging(event: ISimpleComboSelectionChangingEventArgs, label: HTMLLabelElement) {
    if (event.newValue === undefined) {
      label.classList.remove("simple-select-label--active", "simple-select-label--selected")
    }
  }

  multiComboChanging(event: IComboSelectionChangingEventArgs, label: HTMLLabelElement) {
    if (event.displayText === "") {
      label.classList.remove("simple-select-label--active", "simple-select-label--selected")
    }
  }


  searchTypeChanging(event: ISimpleComboSelectionChangingEventArgs | ISimpleComboSelectionChangingEventArgs) {
    if (event.newValue === undefined) {
      event.cancel = true;
    }
  }

  focusInputAndFixedHeight(evt: IBaseCancelableBrowserEventArgs, setHeight: boolean = true) {
    evt.owner.inputGroup.input.element.nativeElement.focus()
    if (!setHeight) return
    evt.owner.dropdown.scrollContainerRef.nativeElement.style.height = "280px";
  }

  customDropDownWidth(evt: IBaseCancelableBrowserEventArgs, width: string, any?: any) {
    evt.owner.dropdownContainer.nativeElement.style.width = width;
  }
}
